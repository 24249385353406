const WhatsAppLink = () => {
    const handleClick = (event) => {
      const userAgent = navigator.userAgent.toLowerCase();
  
      // Verifica se o navegador é Firefox
      if (userAgent.indexOf('firefox') > -1) {
        event.preventDefault();
        window.location.href = `https://www.julefernandes.com.br/contato`;
      }
    };
  
    return (
      <a
        className="menu-item"
        href={`https://wa.me/5511988900227?text=Oi%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20atendimentos.`}
        onClick={handleClick}
      >
        Whatsapp
      </a>
    );
  };

  export default WhatsAppLink;