import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login/Login';
import Register from './Register/Register';
import Dashboard from './Dashboard/Dashboard';
import HomePage from './HomePage';
import FloraisDeBach from './FloraisDeBach';
import FloraisDaLua from './FloraisDaLua';
import Reiki from './Reiki';
import Contato from './Contato';
import { auth } from './firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

const App = () => {
  const [user] = useAuthState(auth);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route 
          path="/dashboard" 
          element={user ? <Dashboard /> : <Navigate to="/login" />} 
        />
        <Route path="/login" element={<Navigate to="/login" />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/florais-de-bach" element={<FloraisDeBach />} />
        <Route path="/florais-da-lua" element={<FloraisDaLua />} />
        <Route path="/Reiki" element={<Reiki />} />
      </Routes>
    </Router>
  );
};

export default App;