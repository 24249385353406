import React from 'react';
import WhatsAppBtn from './WhatsappBtn';
import logoImage from './img/logo.png';
import btnFace from './img/btn-face.png';
import btnInsta from './img/btn-insta.png';
import btnZap from './img/btn-zap.png';
import videoUrl from './img/bg2lowlow.mp4';
import arrowLeft from './img/arrow-left.png';

const FloraisDaLua = () => {
  return (
    <div className="container bach">
      <video autoPlay loop muted playsInline className="background-video">
        <source src={videoUrl} type="video/mp4" />
        Seu navegador não suporta o vídeo.
      </video>
      <div className="flex-home">
        <div className="header">
          <a href="/"><img src={logoImage} alt="Jule Fernandes Logo" className="logo" /></a>
        </div>
        <div>
          <div className="menu">
            <span className="title" >
            <div className="alg-center ft-w-b p-r">
                <a href="/"><img src={arrowLeft} alt="arrow left"/></a>
                <span>Florais da Lua</span>
              </div>
            </span>
            <WhatsAppBtn title={"MODO ONLINE"} description={"Atendimento através de chamada de vídeo e conversa terapêutica com indicação de Floral da Lua."} />
            <WhatsAppBtn title={"ENCONTRO EM RODA"} description={"Encontro presencial em roda de mulheres, com indicação de Floral da Lua."} />
          </div>
        </div>
      </div>
        <div className="footer">
          <a href="https://www.facebook.com/profile.php?id=61564553529679"><img src={btnFace} alt="Facebook" className="social-icon" /></a>
          <a href="https://www.instagram.com/souleu_julefernandes/"><img src={btnInsta} alt="Instagram" className="social-icon" /></a>
          <a href="https://wa.me/5511988900227?text=Oi%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20atendimentos."><img src={btnZap} alt="Whatsapp" className="social-icon" /></a>
        </div>
    </div>
  );
};

export default FloraisDaLua;