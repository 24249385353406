import React from 'react';
import './HomePage.css';
import WhatsAppLink from './Whatsapp';
import backgroundImage from './img/bg.png';
import logoImage from './img/logo.png';
import btnFace from './img/btn-face.png';
import btnInsta from './img/btn-insta.png';
import btnZap from './img/btn-zap.png';

const HomePage = () => {
  return (
    <div className="container home" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="flex-home">
        <div className="header">
          <img src={logoImage} alt="Jule Fernandes Logo" className="logo" />
        </div>
        <div>
          <div className="menu">
            <a className="menu-item" href="/florais-de-bach"><div>Florais de Bach</div></a>
            <a className="menu-item" href="/florais-da-lua"><div>Florais da Lua</div></a>
            <a className="menu-item" href="/reiki"><div>Reiki</div></a>
            <WhatsAppLink />
          </div>
        </div>
      </div>
      <div className="footer">
          <a href="https://www.facebook.com/profile.php?id=61564553529679"><img src={btnFace} alt="Facebook" className="social-icon" /></a>
          <a href="https://www.instagram.com/souleu_julefernandes/"><img src={btnInsta} alt="Instagram" className="social-icon" /></a>
          <a href="https://wa.me/5511988900227?text=Oi%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20atendimentos."><img src={btnZap} alt="Whatsapp" className="social-icon" /></a>
        </div>
    </div>
  );
};

export default HomePage;





