import React from 'react';
import './Contato.css';
import backgroundImage from './img/bg-contato.png';
import txtContato from './img/txt-contato.png';

const Contato = () => {
  return (
    <div className="container" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="txt-contato">
            <img src={txtContato} alt="texto contato telefone fale conosco"/>
        </div>
    </div>
  );
};

export default Contato;